/* Order List, Vue Component */
<template>
  <v-card id="order-list">
    <v-card-title v-if="title">{{ title }}</v-card-title>

    <v-card-text v-if="!hideFilter" class="d-flex align-center flex-wrap pb-0">

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mb-4"
        ></v-text-field>

         <v-text-field v-model="search.Reference"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Reference"
          class="mb-4"
        ></v-text-field>

        <v-text-field v-model="search.MemberId_Reference"
          single-line
          dense
          outlined
          hide-details
          placeholder="Member Reference"
          class="mb-4"
        ></v-text-field>

        <v-text-field v-model="search.MemberId_FullName"
          single-line
          dense
          outlined
          hide-details
          placeholder="Member Name"
          class="mb-4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <OrderStatusSelect clearable multiple class="mb-4" v-model="filter.StatusIds" :outlined="true" :inverted="false" :hide-details="true" :dense="true" />

        <ProductSelect class="mb-4" v-model="filter.ProductId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" />

        <!-- <ManifestSelect class="mb-4 mr-4" v-model="filter.ManifestId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" /> -->

        <!-- <MemberLookup class="mb-4 mr-4" v-model="filter.MemberId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" /> -->

        <!-- <CountrySelect class="mb-4 mr-4" placeholder="Select Delivery Country" v-model="filter.DeliveryAddressId_CountryId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" /> -->

      </v-col>
    </v-row>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.Reference`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'order-view', params: { id: item.Id } }">
        {{ item.Reference }}
      </router-link>
    </template>

    <template #[`item.MemberId_Reference`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'member-view', params: { id: item.MemberId } }">
        {{ item.MemberId_Reference }}
      </router-link>
    </template>

    <template #[`item.StatusId_Name`]="{item}">
      <order-status-chip :status="item.StatusId_Name"></order-status-chip>
    </template>

    <template #[`item.ProductId_Name`]="{item}">
      <div v-if="item.BespokePrice">
        <v-chip title="Custom Shipment" small color="info" outlined class="v-chip-light-bg">
           <v-icon class="me-1" size="16">{{ icons.mdiCog }}</v-icon>
           {{ item.CustomLength }}x{{ item.CustomWidth }}x{{ item.CustomHeight }} mm
         </v-chip>
      </div>
      <div v-else-if="item.ProductId_Name">
        {{ item.ProductId_Code}} - {{ item.ProductId_Name}}
      </div>
      <div v-else class="caption d-flex">
         <v-chip title="Quote" small color="warning" outlined class="v-chip-light-bg">
           <v-icon class="me-1" size="16">{{ icons.mdiBookEditOutline }}</v-icon>
           {{ item.CustomLength }}x{{ item.CustomWidth }}x{{ item.CustomHeight }} mm
         </v-chip>
      </div>
    </template>

    <template #[`item.Updated`]="{item}">
      {{ Display.DateTime(item.Updated) }}
    </template>

		<!-- <template #[`item.ProductId_Price`]="{item}">
      {{ Display.Money(item.ProductId_Price) }}
    </template> -->


    <template #[`item.DeliveryAddressId_Summary`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.DeliveryAddressId_Summary }}</div>
        </template>
        <span>{{ item.DeliveryAddressId_Summary }}</span>
      </v-tooltip>
    </template>

    <template #[`item.MemberId_Email`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.MemberId_Email }}</div>
        </template>
        <span>{{ item.MemberId_Email }}</span>
      </v-tooltip>
    </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <ActionsDropdown :item="item" :actionOptions="actionOptions" />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiBookEditOutline,
  mdiCog
} from '@mdi/js'

import { ref, watch, computed, toRefs } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import ActionsDropdown from '@/views/helpers/ActionsDropdown'
import OrderStatusSelect from '@/views/orderstatus/OrderStatusSelect'
import ProductSelect from '@/views/product/ProductSelect'
import ManifestSelect from '@/views/manifest/ManifestSelect'
//import MemberSelect from '@/views/member/MemberSelect'
import MemberLookup from '@/views/member/MemberLookup'
// import AddressSelect from '@/views/address/AddressSelect'
import CountrySelect from '@/views/country/CountrySelect'
import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'

export default {
   props: {
    title: {
      required: false,
      default: "Orders"
    },
    defaultFilter: {
      type: Object,
      required: false,
      default: null
    },
    hideFilter: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    ActionsDropdown,
    OrderStatusSelect,
		ProductSelect,
		ManifestSelect,
		//MemberSelect,
    MemberLookup,
		// AddressSelect
    CountrySelect,
    OrderStatusChip
  },
  setup(props) {

    const { title, defaultFilter, hideFilter } = toRefs(props);

  // define cols
  const tableColumns = [
    { text: 'Reference', value: 'Reference' },
		{ text: 'Status', value: 'StatusId_Name' },
		{ text: 'Updated', value: 'Updated' },
		// { text: 'Order Date', value: 'Created' },
		// { text: 'Product', value: 'OrderType' },
		{ text: 'Product', value: 'ProductId_Name' },
		// { text: 'Price', value: 'ProductId_Price' },
		{ text: 'Member', value: 'MemberId_Reference' },
		{ text: 'Member Email', value: 'MemberId_Email' },
		{ text: 'Delivery', value: 'DeliveryAddressId_Summary' },
		// { text: 'Delivery', value: 'DeliveryAddressId_SummaryExtended' },
		// { text: 'Billing', value: 'BillingAddressId_Summary' },
		// { text: 'Billing', value: 'BillingAddressId_SummaryExtended' },
		{ text: 'Ret. Ref.', value: 'RetailerReference' },
		{ text: 'Items', value: 'Item_Count' },
    { text: 'Outstanding Invoice', value: 'Invoice_UnpaidCount' },
		// { text: 'Retailer Tracking Reference', value: 'RetailerTrackingReference' },
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  // states needed for filter
  const filterOrderStatuses = computed(() => store.getters["app/OrderStatusDefaultFilterForOrderList"]);

  // threee params for fetching
  const search = ref({});
  let filter = ref({});
  if (defaultFilter.value) {
    filter.value = defaultFilter.value;
  } else {
    filter.value = { StatusIds: filterOrderStatuses.value.map(x => parseInt(x)) };
  }

  const options = ref({
    sortBy: ['Updated'],
    sortDesc: [true],
    itemsPerPage: 20
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.Orders || []);
  const totalItems = computed(() => store.state.app.OrdersTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);


  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchOrders', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });


  const booleanIcons = status => {
    if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
    return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
  }


    const actionOptions = [
      { title: 'View', icon: mdiEyeOutline, target: 'order-view' },
      { title: 'Edit', icon: mdiPencilOutline, target: 'order-edit' }
    ]

    return {
      tableColumns,
      search,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      actionOptions,
      footerProps,
      booleanIcons,
      Validators,
      Display,
      icons: {
        mdiPlus,
        mdiBookEditOutline,
        mdiCog
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
